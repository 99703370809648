import { ITile } from '../../../../slice/@types/ITile'

export type Language = 'pl' | 'en';

export interface IDownloadAnalyseInitialValues {
  reportName: string;
  data: 'choosen' | 'all';
  tiles: ITile[];
  reportLanguage: Language;
  projectType: string,
  newProjectName: string,
  projectName: string,
}

export const initialValues: IDownloadAnalyseInitialValues = {
  reportName: '',
  data: 'all',
  tiles: [],
  reportLanguage: 'pl',
  projectType: 'placeme.save_analyse.public',
  newProjectName: '',
  projectName:'',
}

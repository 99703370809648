import React, { FC, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import 'rc-slider/assets/index.css'
import {
  TextButton,
  VerticalDivider,
  ButtonProduct,
  MapTile,
  Header,
} from '@dataplace.ai/ui-components/atoms'
import { useSelector } from 'react-redux'
import { IRange, RangeType } from '@dataplace.ai/types'
import { useTranslation } from 'react-i18next'
import { GeojsonLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { rangeTypes } from '@dataplace.ai/constants'
import { RootState } from '../../../../../redux/store'
import { ICustomRangeModalProps } from './@types/ICustomRangeModal.props'
import { CustomRangeModalBar } from '../../molecules'
import { handleRangeLabel, handleRangeValue, handleRangeIcon } from '../RangeTile'
import { addNewRangeAction, saveTemporaryCatchment } from '../../../slice/analysisSlice'

const StyledButtonProduct = styled(ButtonProduct)`
  margin-right: 1.25rem;
`

const Wrapper = styled.div(({
  theme: {
    shadows,
    palette,
  },
}) => css`
  width: 80vw;
  height: 70vh;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  background-color: ${palette.light.white};
  border-radius: 12px;
  box-shadow: ${shadows.hover.boxShadow};
`)

const ModalHeaderSection = styled.div(({ theme: { palette } }) => css`
  padding-bottom: 1.875rem;
  display: flex;
  align-items: center;
  color: ${palette.black};
`)

const StyledTextButton = styled(TextButton)(({
  theme: {
    typography,
    palette,
  },
}) => css`
  margin-left: auto;
  margin-right: 1.82rem;
  display: flex;
  align-items: center;
  color: ${palette.blue};
  font-size: ${typography.small.pt_13_medium.fontSize};
  font-weight: ${typography.small.pt_13_medium.fontWeight};
  line-height: ${typography.small.pt_13_medium.lineHeight};
`)

const ChosenRangeWrapper = styled.div(({ theme: { palette } }) => css`
  display: flex;
  align-items: center;
  color: ${palette.black};
  margin-left: 1.25rem;
`)

const Value = styled.p(({ theme: { typography } }) => css`
  font-size: ${typography.big.pt_22_semibold.fontSize};
  font-weight: ${typography.big.pt_22_semibold.fontWeight};
  line-height: ${typography.big.pt_22_semibold.lineHeight};
  margin: 0 0 0 8px;

  span {
    margin-left: 0.25rem;
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
  }
`)

const Location = styled.p(({ theme: { typography } }) => css`
  font-size: ${typography.main.pt_15_regular.fontSize};
  font-weight: ${typography.main.pt_15_regular.fontWeight};
  line-height: ${typography.main.pt_15_regular.lineHeight};
  margin-top: 6px;
  margin-left: 5px;
`)

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.08), 0 0 24px rgba(95, 82, 73, 0.08);
`

export const CustomRangeModal: FC<ICustomRangeModalProps> = ({
  handleClose,
  onlyMap,
  handleRangeSave,
  maxRanges,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { value } = useSelector((state: RootState) => state.location)
  const { layers } = useSelector((state: RootState) => state.analysis)

  const handleCustomRangeSave = () => {
    dispatch(addNewRangeAction(customRange))
    if (!handleRangeSave) return

    handleRangeSave(customRange)
  }
  const [removingDrawRangeLayers, setRemovingDrawRangeLayers] = useState(false)
  const [isDrawRangeChosen, setDrawRangeChoose] = useState(false)
  const [customRange, setCustomRange] = useState<IRange>({
    id: 'walk-8',
    type: rangeTypes.walk as RangeType,
    value: 8,
  })
  const [acceptButtonDisabled, setAcceptButtonDisabled] = useState(false)
  const [isRangeDrawn, setIsRangeDrawn] = useState(false)
  const [temporaryLayer, setTemporaryLayer] = useState<GeojsonLayers[] | undefined>(undefined)
  const [isLayerVisible, setIsLayerVisible] = useState(true)

  const handleDrawRange = (newRange: IRange) => {
    setAcceptButtonDisabled(false)
    setCustomRange(newRange)
  }

  useEffect(() => {
    setAcceptButtonDisabled(isDrawRangeChosen)
  }, [isDrawRangeChosen])

  const drawPolygon = () => {
    if (isDrawRangeChosen) return
    const buttonToClick = document.body.getElementsByClassName('leaflet-draw-draw-polygon')[1] as HTMLElement

    if (!buttonToClick) return
    setCustomRange(state => ({
      ...state,
      id: `custom${0}`,
      type: rangeTypes.custom as RangeType,
      value: 0,
    }))
    buttonToClick.click()
    setDrawRangeChoose(true)
    setRemovingDrawRangeLayers(false)
    setIsRangeDrawn(false)

    if (!layers?.temporaryCatchment?.layer) return
    setTemporaryLayer([{
      layer: {
        ...layers.temporaryCatchment.layer,
        options: {
          ...layers.temporaryCatchment.layer.options,
          style: {
            ...layers.temporaryCatchment.layer.options.style,
            fillColor: defaultTheme.palette.dark.normal,
          },
        },
      },
      id: layers?.temporaryCatchment?.id,
    }])
  }

  useEffect(() => {
    if (layers?.temporaryCatchment?.layer) {
      setTemporaryLayer([{
        id: layers?.temporaryCatchment?.id,
        layer: layers?.temporaryCatchment?.layer,
      }])
    } else {
      setTemporaryLayer(undefined)
    }
  }, [layers])

  return (
    <Wrapper>
      <ModalHeaderSection>
        <Header type='h4'>{t('placeme.analyse_range')}</Header>
        <VerticalDivider
          height='23px'
          margin='0'
        />
        <ChosenRangeWrapper>
          {handleRangeIcon(customRange.type, 20, 20)}
          <Value className='value'>
            {handleRangeValue(customRange.type, customRange.value)}
            {handleRangeLabel(customRange.type, customRange.value)}
          </Value>
          <Location>{value?.address}</Location>
        </ChosenRangeWrapper>
        <StyledTextButton
          onClick={() => {
            handleClose()
            dispatch(saveTemporaryCatchment(undefined))
          }}
        >
          {t('generic.cancel')}
        </StyledTextButton>
        {!onlyMap
          ? (
            <StyledButtonProduct
              disabled={acceptButtonDisabled}
              onClick={() => {
                handleCustomRangeSave()
                handleClose()
                dispatch(saveTemporaryCatchment(undefined))
              }}
              small
            >
              {t('generic.approve')}
            </StyledButtonProduct>
          )
          : null}
      </ModalHeaderSection>
      <Content>
        <CustomRangeModalBar
          customRange={customRange}
          drawPolygon={drawPolygon}
          isDrawRangeChosen={isDrawRangeChosen}
          isLayerVisible={isLayerVisible}
          isRangeDrawn={isRangeDrawn}
          maxRanges={maxRanges}
          setCustomRange={setCustomRange}
          setDrawRangeChoose={setDrawRangeChoose}
          setIsLayerVisible={setIsLayerVisible}
          setIsRangeDrawn={setIsRangeDrawn}
          setRemoveCustomLayers={setRemovingDrawRangeLayers}
          temporaryLayer={temporaryLayer}
        />
        <MapTile
          dragging
          drawPolygon={drawPolygon}
          handleDraw={handleDrawRange}
          height='100%'
          layers={isLayerVisible ? temporaryLayer : undefined}
          location={value}
          mapId={`custom-range-bar-${customRange?.id}`}
          mapTypeDropdown
          maxRange={maxRanges?.custom}
          pinDisplayed
          popupHeading={t('generic.chosen_location')}
          popupParagraph={value?.address}
          removingDrawRangeLayers={removingDrawRangeLayers}
          scroll
          setDrawRangeChoose={setDrawRangeChoose}
          showScaleControl
          width='100%'
          zoomControl
        />
      </Content>
    </Wrapper>
  )
}

import React from 'react'
import { VerticalDivider } from '@dataplace.ai/ui-components/atoms'
import styled from 'styled-components'
import { ReactComponent as MarkOnMapIcon } from 'libs/shared/assets/src/lib/icons/rangeIcons/markOnMap.svg'
import { GreenTextButton } from './CustomRangeModalBar'

const StyledMarkOnMapIcon = styled(MarkOnMapIcon)`
  margin-right: 5px;
`

interface MarkOnMapSectionProps {
  drawPolygon: () => void;
  disabled: boolean;
  label: string;
  showMarkOnMapButton: boolean;
}

const MarkOnMapSection: React.FC<MarkOnMapSectionProps> = ({
  drawPolygon,
  disabled,
  label,
  showMarkOnMapButton,
}) => (
  showMarkOnMapButton
    ? (
      <>
        <VerticalDivider
          height='2.5rem'
          margin='0 1.25rem'
        />
        <GreenTextButton
          disabled={disabled}
          onClick={drawPolygon}
        >
          <StyledMarkOnMapIcon />
          {label}
        </GreenTextButton>
      </>
    )
    : null
)

export default MarkOnMapSection

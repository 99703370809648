import { rangeTypes } from '@dataplace.ai/constants'
import { RangeType } from '@dataplace.ai/types'
import { handleRangeIcon } from '@placeme/components/organisms'
import React from 'react'
import styled, { css } from 'styled-components'

const RangeTypeButton = styled.button<{active: boolean}>(({
  theme: {
    palette,
    typography,
  },
  active,
}) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  cursor: pointer;
  background: ${palette.dark.main};
  border: none;
  font-size: ${typography.tiny.pt_12_regular.fontSize};
  font-weight: ${typography.tiny.pt_12_regular.fontWeight};
  line-height: ${typography.tiny.pt_12_regular.lineHeight};

  :first-child {
    border: 1px solid ${palette.product.location.main};
    border-radius: 6px 0 0 6px;
  }

  :nth-child(3) {
    border-left: 1px solid ${palette.product.location.main};
  }

  :nth-child(4) {
    border: 1px solid ${palette.product.location.main};
    border-radius: 0 6px 6px 0;
  }

  svg {
    margin: 0 .3rem;
      path {
        fill: ${palette.light.white};
      }
  }

  :disabled{
    svg {
      path {
        fill: ${palette.product.location.medium}
      }
    }
  }

  ${active && css`
    background: ${palette.results.green.dark};
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    color: ${palette.light.white};
  `}
`)

interface RangeTypeSelectorProps {
  customRangeType: string;
  isDrawRangeChosen: boolean;
  handleTypeButton: (type: RangeType, value: number) => void;
}

const rangeTypesConfigs = [
  {
    type: rangeTypes.walk,
    value: 8,
  },
  {
    type: rangeTypes.car,
    value: 8,
  },
  {
    type: rangeTypes.bike,
    value: 8,
  },
  {
    type: rangeTypes.line,
    value: 1000,
  },
]

const RangeTypeSelector: React.FC<RangeTypeSelectorProps> = ({
  customRangeType,
  isDrawRangeChosen,
  handleTypeButton,
}) => (
  <>
    {rangeTypesConfigs.map(({
      type, value,
    }) => (
      <RangeTypeButton
        key={type}
        active={customRangeType === type}
        disabled={isDrawRangeChosen}
        onClick={() => handleTypeButton(type as RangeType, value)}
      >
        {handleRangeIcon(type as RangeType, 15, 15)}
      </RangeTypeButton>
    ))}
  </>
)

export default RangeTypeSelector

import { DownloadAnalyseModalV2 } from '@placeme/components/molecules/DownloadAnalyseModalV2'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { DownloadPDFModal } from '@placeme/components/molecules'
import DownloadPdfButton from './DownloadPdfButton'

interface DownloadAnalyseProps {
  comparedReportId?: string
  reportId: string
  downloadDisabled: boolean
  isTrial: boolean
  wasLocationSaved?: boolean
}

const DownloadAnalyse: React.FC<DownloadAnalyseProps> = ({
  comparedReportId,
  reportId,
  downloadDisabled,
  isTrial,
  wasLocationSaved,
}) => {
  const {
    canBeSave, isEdit,
  } = useSelector((state: RootState) => state.analysis)
  const address = useSelector((state: RootState) => state.location?.value?.address || '')

  return (
    <>
      {!(canBeSave && !isEdit)
        ? (
          <DownloadPDFModal
            address={address}
            comparedReportId={comparedReportId}
            reportId={reportId}
            trigger={(
              <DownloadPdfButton
                downloadDisabled={downloadDisabled}
                isTrial={isTrial}
              />
            )}
          />
        )
        : (
          <DownloadAnalyseModalV2
            analyseId={reportId}
            comparedReportId={comparedReportId}
            trigger={(
              <DownloadPdfButton
                downloadDisabled={downloadDisabled}
                isTrial={isTrial}
              />
            )}
            wasLocationSaved={wasLocationSaved}
          />
        )}
    </>
  )
}

export default DownloadAnalyse

import React, { FC, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import 'rc-slider/assets/index.css'
import { TextButton } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { useSelector } from 'react-redux'
import { RangeType } from '@dataplace.ai/types'
import { rangeTypes } from '@dataplace.ai/constants'
import { ICustomRangeModalBarProps } from './@types/ICustomRangeModalBar.props'
import { RangeSlider } from '../../atoms'
import { fetchTemporaryCatchment, saveTemporaryCatchment } from '../../../slice/analysisSlice'
import { RootState } from '../../../../../redux/store'
import RangeTypeSelector from './RangeTypeSelector'
import RangeActions from './RangeActions'

const StyledTextButton = styled(TextButton)(({
  theme: {
    typography,
    palette,
  },
}) => css`
  display: flex;
  align-items: center;
  font-size: ${typography.small.pt_13_medium.fontSize};
  font-weight: ${typography.small.pt_13_medium.fontWeight};
  line-height: ${typography.small.pt_13_medium.lineHeight};
  color: ${palette.light.white};
`)

export const GreenTextButton = styled(StyledTextButton)(({ theme: { palette } }) => css`
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 6px;

  :disabled {
    border-radius: 6px 0px 0px 6px;
    background-color: ${palette.results.green.dark};
  }
`)

export const StyledGreenTextButton = styled(GreenTextButton)(({ theme: { palette } }) => css`
  margin-left: 21px;
  background-color: ${palette.results.green.dark};
  color: ${palette.light.white};

  :disabled {
    background-color: transparent;
    color: ${palette.product.location.medium};
  }
`)

const Wrapper = styled.div(({ theme: { palette } }) => css`
  min-height: 52px;
  border-radius: 12px 12px 0 0;
  background-color: ${palette.product.location.main};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px 10px 15px;
`)

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const CustomRangeModalBar: FC<ICustomRangeModalBarProps> = ({
  customRange,
  setCustomRange,
  onlyMap,
  setRemoveCustomLayers,
  isDrawRangeChosen,
  setDrawRangeChoose,
  maxRanges,
  drawPolygon,
  isRangeDrawn,
  setIsRangeDrawn,
  setIsLayerVisible,
  isLayerVisible,
  temporaryLayer,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => setToken(response))
  }, [authContext])

  const findCancelButton = (): HTMLElement | null => {
    const cancelPolygonButtons = document.querySelectorAll('.leaflet-draw-actions')
    const cancelButton = cancelPolygonButtons?.[2]

    return cancelButton?.querySelector('li:nth-child(3) > a')
  }

  const deleteDrawPolygon = () => {
    dispatch(saveTemporaryCatchment(undefined))
    setCustomRange(state => ({
      ...state,
      id: 'walk-8',
      type: rangeTypes.walk as RangeType,
      value: 8,
    }))

    findCancelButton()?.click()
    setDrawRangeChoose(false)
    setRemoveCustomLayers(true)
  }

  const handleRangeTypeChange = (type: RangeType, value: number) => {
    setCustomRange(state => ({
      ...state,
      id: type + state.value,
      type,
      value,
    }))
  }

  const handleTypeButton = (type: RangeType, rangeValue: number) => {
    handleRangeTypeChange(type, rangeValue)
    setRemoveCustomLayers(true)
    if (isDrawRangeChosen) deleteDrawPolygon()

    dispatch(saveTemporaryCatchment(undefined))
    setIsRangeDrawn(false)
  }

  const handleRemoveLayerFromSlider = () => {
    if (isDrawRangeChosen) deleteDrawPolygon()

    setRemoveCustomLayers(true)
    dispatch(saveTemporaryCatchment(undefined))
    setIsRangeDrawn(false)
  }

  return (
    <Wrapper>
      <SelectWrapper>
        {!onlyMap
          ? (
            <>
              <RangeTypeSelector
                customRangeType={customRange.type}
                handleTypeButton={handleTypeButton}
                isDrawRangeChosen={isDrawRangeChosen}
              />
              <RangeSlider
                handleRemoveLayers={handleRemoveLayerFromSlider}
                maxRanges={maxRanges}
                setCustomRange={setCustomRange}
                type={customRange.type}
              />
              <StyledGreenTextButton
                disabled={isDrawRangeChosen || isRangeDrawn}
                onClick={() => {
                  setIsRangeDrawn(true)
                  dispatch(fetchTemporaryCatchment(token, {
                    length: customRange.value,
                    lat: value?.lat || 0,
                    lng: value?.lng || 0,
                    mode: customRange.type,
                  }))
                }}
              >
                {t('generic.see_on_map')}
              </StyledGreenTextButton>
              <RangeActions
                comparedLocation={comparedLocation}
                deleteDrawPolygon={deleteDrawPolygon}
                drawPolygon={drawPolygon}
                isDrawRangeChosen={isDrawRangeChosen}
                isLayerVisible={isLayerVisible}
                setIsLayerVisible={setIsLayerVisible}
                temporaryLayer={temporaryLayer}
              />
            </>
          )
          : null}
      </SelectWrapper>
    </Wrapper>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormSection, Select, TextInput } from '@dataplace.ai/ui-components/molecules'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Checkbox, CloseIcon, TextButton } from '@dataplace.ai/ui-components/atoms'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { IAnalysesData } from 'apps/placeme/src/features/AnalyseAndReports/components/organisms/AnalyseAndReportBody/@types/IAnalysesData'
import styled from 'styled-components'
import { useFeatureAccess, useSaveLocation } from '@dataplace.ai/functions/hooks'
import { featureAccessIds } from 'apps/placeme/src/constants/constants'
import { ReactComponent as FolderIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/profile/folder.svg'
import { BottomWrapper, Description } from './DownloadModal.styles'
import { IDownloadAnalyseInitialValues, initialValues } from './utils/initialValues'
import { saveAnalyse, saveSavingState } from '../../../slice/analysisSlice'
import { projectTypes } from './utils/constants'
import { fetchProjectId, fetchExistingReports, folderNames } from './utils/functions'

const StyledCloseIcon = styled(CloseIcon)`
  transform: rotate(45deg);
  cursor: pointer;
  margin-right: 5px;
`

const StyledParagraph = styled.p`
  display: flex;
  align-items: center;
`

const BottomSelect = styled.div`
  margin-top: 8px;
`

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 1rem;

  > div {
    > input[type='checkbox'], > input[type='checkbox'] + div {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`

interface DownloadAnalyseFormProps {
  analyseId: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  existingProjects: ({ folderId: string; folderName: string; } | null)[]
  downloadPDF: () => Promise<void>
  pdfStateLoading: boolean
  wasLocationSaved?: boolean
}

const DownloadAnalyseForm: React.FC<DownloadAnalyseFormProps> = ({
  analyseId,
  setOpen,
  existingProjects,
  downloadPDF,
  pdfStateLoading,
  wasLocationSaved,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isNewProject, setIsNewProject] = useState(false)
  const {
    canBeSave, isEdit, savingState, analysisDbInfo,
  } = useSelector((state: RootState) => state.analysis)
  const {
    userType, currentWorkspaceData,
  } = useSelector((state: RootState) => state.location)
  const { data } = useSelector((state: RootState) => state.analysesAndReports)
  const projectsData: IAnalysesData = data?.value as IAnalysesData
  const [shouldSaveLocation, setShouldSaveLocation] = useState(false)
  const { hasFeatureAccess } = useFeatureAccess()
  const hasKanbanAccess = hasFeatureAccess(featureAccessIds.kanban)
  const { saveLocation } = useSaveLocation()

  const canSaveLocation = useMemo(() => (
    hasKanbanAccess && !wasLocationSaved && !analysisDbInfo?.locationExists && !analysisDbInfo?.locationId
  ), [hasKanbanAccess, analysisDbInfo?.locationExists, analysisDbInfo?.locationId, userType, wasLocationSaved])

  const isNewProjectToggle = useCallback(() => setIsNewProject((prev) => !prev), [])

  const saveLocationAndGetItsId = useCallback(async () => {
    if (!canSaveLocation || !shouldSaveLocation || !analysisDbInfo) return

    const data = await saveLocation({
      address: analysisDbInfo.address,
      lat: analysisDbInfo.locationLat,
      lng: analysisDbInfo.locationLng,
    })
    return data?.potential.find(loc => loc.address === analysisDbInfo.address)?.id
  }, [analysisDbInfo, canSaveLocation, shouldSaveLocation])

  const handleSubmittedData = async (e: IDownloadAnalyseInitialValues) => {
    downloadPDF()
    dispatch(saveSavingState({
      loading: true,
      error: null,
      value: null,
    }))

    const status = e.projectType.toLowerCase().includes('private') ? 'private' : 'view'
    const savedLocationId = await saveLocationAndGetItsId()
    let projectId
    let projectName

    if (!e.newProjectName.length) {
      projectId = fetchProjectId({
        projectName: e.projectName,
        existingProjects,
      })
    } else {
      projectName = e.newProjectName
    }

    dispatch(saveAnalyse(
      '',
      e.reportName,
      status,
      projectName,
      projectId,
      analyseId,
      fetchExistingReports({
        folderId: projectId,
        data,
        projectsData,
      }),
      savedLocationId,
    ))
  }

  const validationSchema = useMemo(() => Yup.object().shape({
    newProjectName: ((folderNames(existingProjects).length === 0 || isNewProject) && canBeSave && !isEdit)
      ? Yup.string().max(50)
        .notOneOf(folderNames(existingProjects), t('placeme.create_folder.name_is_taken'))
        .required(t('generic.field_is_required'))
      : Yup.string().max(50)
        .notOneOf(folderNames(existingProjects), t('placeme.create_folder.name_is_taken')),
    projectName: (folderNames(existingProjects).length > 0 && !isNewProject && canBeSave && !isEdit)
      ? Yup.string().max(50)
        .required(t('generic.field_is_required'))
      : Yup.string().max(50),
    reportLanguage: Yup.string().required(t('generic.field_is_required')),
    reportName: Yup.string()
      .required(t('generic.field_is_required'))
      .max(100),
    tiles: Yup.array().when('data', {
      is: 'choosen',
      then: Yup.array().min(1),
    }),
  }), [isNewProject, canBeSave, isEdit, existingProjects])

  const noExistingProjects = useMemo(() => folderNames(existingProjects).length === 0, [existingProjects])
  const projectsExist = useMemo(() =>
    folderNames(existingProjects).length > 0 && !isNewProject, [existingProjects, isNewProject])

  useEffect(() => {
    const defaultFolderId = currentWorkspaceData?.value?.settings.defaultReportFolder
    if (defaultFolderId) {
      const defaultFolder = existingProjects.find(proj => proj?.folderId === defaultFolderId)
      if (defaultFolder) {
        setFieldValue('projectName', defaultFolder.folderName)
      }
    }
  }, [currentWorkspaceData?.value?.settings.defaultReportFolder, existingProjects])

  useEffect(() => {
    const saveAsPotential = currentWorkspaceData?.value?.settings.saveLocationAsPotential
    if (typeof saveAsPotential === 'boolean') setShouldSaveLocation(saveAsPotential)
  }, [currentWorkspaceData?.value?.settings.saveLocationAsPotential])

  const {
    errors,
    handleBlur,
    handleSubmit,
    touched,
    values,
    setFieldValue,
    isValid,
  } = useFormik({
    initialValues,
    isInitialValid: !canBeSave || isEdit,
    validateOnBlur: false,
    onSubmit: (e) => {
      handleSubmittedData(e)
    },
    validationSchema,
  })

  return (
    <Form
      centered
      onSubmit={handleSubmit}
    >
      <FormSection>
        <TextInput
          error={touched.reportName && errors.reportName ? errors.reportName : undefined}
          label={t('placeme.download_analyse_modal.label')}
          margin='0 0 1rem'
          name='reportName'
          onBlur={handleBlur}
          onChange={setFieldValue}
          placeholder={t('placeme.download_analyse_modal.type_report_name')}
          reset
          value={values.reportName}
        />
        <>
          <Select
            label={t('generic.save_analyse')}
            name='projectType'
            onChange={setFieldValue}
            options={projectTypes}
            placeholder={t('placeme.save_analyse.choose_project_type')}
            selected={t(values.projectType)}
          />
          {noExistingProjects
            ? (
              <>
                <Description>{t('placeme.save_analyse.create_first_project')}</Description>
                <TextInput
                  error={
                    touched.newProjectName && errors.newProjectName
                      ? errors.newProjectName
                      : undefined
                  }
                  icon={<FolderIcon />}
                  label={t('placeme.save_analyse.project_name')}
                  margin='10px 0 0'
                  name='newProjectName'
                  onBlur={handleBlur}
                  onChange={setFieldValue}
                  placeholder={t('placeme.save_analyse.type_project_name')}
                  reset
                  value={values.newProjectName}
                />
              </>
            )
            : null}
          {projectsExist
            ? (
              <>
                <Description>{t('placeme.save_analyse.choose_project')}</Description>
                <Select
                  error={
                    touched.projectName && errors.projectName
                      ? errors.projectName
                      : undefined
                  }
                  icon={<FolderIcon />}
                  label={t('placeme.save_analyse.project_name')}
                  maxHeight='155px'
                  name='projectName'
                  onChange={setFieldValue}
                  options={folderNames(existingProjects)}
                  placeholder={t('placeme.save_analyse.choose_project')}
                  selected={values.projectName}
                  withScroll
                />
                <BottomSelect>
                  <TextButton onClick={isNewProjectToggle}>
                    <StyledParagraph>
                      <StyledCloseIcon />
                      {t('placeme.save_analyse.add_new_project')}
                    </StyledParagraph>
                  </TextButton>
                </BottomSelect>
              </>
            )
            : null}
          {isNewProject
            ? (
              <>
                <Description>{t('placeme.save_analyse.add_new_project')}</Description>
                <TextInput
                  error={
                    touched.newProjectName && errors.newProjectName
                      ? errors.newProjectName
                      : undefined
                  }
                  icon={<FolderIcon />}
                  label={t('placeme.save_analyse.project_name')}
                  margin='10px 0'
                  name='newProjectName'
                  onBlur={handleBlur}
                  onChange={setFieldValue}
                  placeholder={t('placeme.save_analyse.type_project_name')}
                  reset
                  value={values.newProjectName}
                />
                <TextButton onClick={isNewProjectToggle}><p>{t('placeme.save_analyse.choose_project')}</p></TextButton>
              </>
            )
            : null}
          {canSaveLocation
            ? (
              <CheckboxWrapper>
                <Checkbox
                  checked={shouldSaveLocation}
                  label={t('placeme.save_analyse.save_location')}
                  onChange={() => setShouldSaveLocation(!shouldSaveLocation)}
                />
              </CheckboxWrapper>
            )
            : null}
        </>
        <BottomWrapper>
          <TextButton
            onClick={() => setOpen(false)}
            type='button'
          >
            <p>{t('generic.cancel')}</p>
          </TextButton>
          <Button
            disabled={isNewProject && !isValid}
            loading={pdfStateLoading || savingState.loading}
            loadingText={`${t('placeme.download_analyse_modal.button.loading')}...`}
            margin='0 0 0 20px'
            type='submit'
          >
            {t('placeme.download_analyse_modal.button')}
          </Button>
        </BottomWrapper>
      </FormSection>
    </Form>
  )
}

export default DownloadAnalyseForm

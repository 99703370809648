import React from 'react'
import { LinkWithIconDelete } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { GeojsonLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { useTranslation } from 'react-i18next'
import { ILocation } from '@dataplace.ai/types'
import { StyledGreenTextButton } from './CustomRangeModalBar'
import MarkOnMapSection from './MarkOnMapSection'

interface RangeActionsProps {
  isDrawRangeChosen: boolean;
  comparedLocation: {
    location: ILocation;
    alreadyGenerated: boolean;
    generatedFromNow: boolean;
  } | undefined;
  drawPolygon: () => void;
  deleteDrawPolygon: () => void;
  temporaryLayer: GeojsonLayers[] | undefined;
  isLayerVisible: boolean;
  setIsLayerVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledLinkWithIconDelete = styled(LinkWithIconDelete)(({ theme: { palette } }) => css`
  background-color: ${palette.dark.main};
  padding: 10px 15px;
  border-radius: 0px 6px 6px 0px;
  min-height:41px;

  :hover {
    color: ${palette.light.white};
    background-color: ${palette.results.red.light};
    > svg > path {
      stroke: ${palette.light.white};
    }
  }
`)

const RangeActions: React.FC<RangeActionsProps> = ({
  isDrawRangeChosen,
  comparedLocation,
  drawPolygon,
  deleteDrawPolygon,
  temporaryLayer,
  isLayerVisible,
  setIsLayerVisible,
}) => {
  const { t } = useTranslation()
  const showMarkOnMapButton = !comparedLocation?.generatedFromNow && !comparedLocation?.alreadyGenerated

  return (
    <>
      {!isDrawRangeChosen
        ? (
          <MarkOnMapSection
            disabled={false}
            drawPolygon={drawPolygon}
            label={t('generic.mark_on_map')}
            showMarkOnMapButton={showMarkOnMapButton}
          />
        )
        : (
          <>
            <MarkOnMapSection
              disabled
              drawPolygon={drawPolygon}
              label={t('generic.mark_on_map')}
              showMarkOnMapButton={showMarkOnMapButton}
            />
            <StyledLinkWithIconDelete
              label='generic.cancel'
              onClick={deleteDrawPolygon}
            />
            {temporaryLayer
              ? (
                <StyledGreenTextButton onClick={() => setIsLayerVisible((prev) => !prev)}>
                  {isLayerVisible ? t('placeme.map.hide_layer') : t('placeme.map.show_layer')}
                </StyledGreenTextButton>
              )
              : null}
          </>
        )}
    </>
  )
}

export default RangeActions
